<template>
  <div>
    <v-tabs-items v-model="selectedAppBarTab">
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <LocationVariationList
          :location="location"
          :zone="tab.key"
          :variations="variationsInZone(tab.key)"
          :key="renderKey"
        />
      </v-tab-item>
    </v-tabs-items>
    <LocationVariationDialog
      mode="create"
      v-if="showLocationVariationDialog"
      :location-u-u-i-d="location.uuid"
      v-model="showLocationVariationDialog"
      @closed="closeLocationVariationDialog"
    />
  </div>
</template>

<script>

  import LocationVariationList from '@/components/LocationVariationList'
  import LocationVariationDialog from "@/components/location/LocationVariationDialog"

  export default {
    name: 'Zones',
    components: {
      LocationVariationDialog,
      LocationVariationList
    },
    data: () => ({
      showLocationVariationDialog: false,
      renderKey: 0
    }),
    methods: {
      variationsInZone: function(zoneUUID) {
        if(this.variations === null) {
          return []
        }

        return this.variations.filter(variation => variation.zone !== null && variation.zone !== undefined && variation.zone.uuid === zoneUUID)
      },
      closeLocationVariationDialog: function() {
        this.$store.dispatch('getLocations')
        this.renderKey = this.renderKey + 1
        this.showLocationVariationDialog = false
      },
    },
    computed: {
      zones() {
        const zoneIndex = []
        const zones = []

        for(let i in this.location.variations) {
          const variation = this.location.variations[i]

          if(variation && variation.zone && !zoneIndex.includes(variation.zone.uuid)) {
            zoneIndex.push(variation.zone.uuid)
            zones.push(variation.zone)
          }

        }

        return zones
      },
      tabs() {

        const tabs = []

        if(this.zones !== null && this.zones !== undefined && this.zones.length > 0) {

          for(let tabIndex in this.zones) {
            tabs.push({
              title: this.zones[tabIndex].name,
              key: this.zones[tabIndex].uuid
            })
          }
        }

        return tabs
      },
      location() {
        return this.$store.state.locations.find(location => { return location.uuid === this.$route.params.uuid })
      },
      variations() {
        if(this.location === null) {
          return []
        } else {
          return this.location.variations
        }
      },
      selectedAppBarTab() {
        return this.$store.state.selectedAppBarTab
      }
    },
    mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)

      if(this.tabs.length > 0) {
        this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
      }

      this.$store.dispatch('getEtags')
      this.$store.commit('updateAppBarFab', {
        color: 'pink',
        callback: function() {this.showLocationVariationDialog = true}.bind(this)
      })
    }
  }
</script>
